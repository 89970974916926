import { Router } from "@solidjs/router";
import type { ParentProps } from "solid-js";
import {
  ErrorBoundary,
  Suspense,
  getOwner,
  onCleanup,
  onMount,
} from "solid-js";

import { HEADER_LOGO, MODAL_NAV, NAVBAR } from "@base/consts/elements";
import { isPhone } from "@base/env";
import { isModal } from "@base/utils/modal-menu";

import { handleNavigate } from "@utils/navigate";

import { Loader } from "../custom/Loader";
import { Portal2 } from "../custom/Portal2";
import ErrorComponent from "../pages/Error";
import { MenuButton } from "./MenuButton";
import { Nav } from "./Nav";
import { routes } from "./routes";

const headerLogo = document.getElementById(HEADER_LOGO)!;
const createMenuButtonDiv = () => {
  const div = document.createElement("div");
  // div.id = "menu-button";
  return div;
};
const createNavContainerDiv = () => {
  const div = document.createElement("div");
  div.className = "menu";
  return div;
};
const isMobile = isPhone();
const navMount = isMobile
  ? document.getElementById(MODAL_NAV)!
  : document.getElementById(NAVBAR)!;

const MainWrapper = (props: ParentProps) => {
  const owner = getOwner()!;

  const onClick = (e: MouseEvent) => {
    const target = e.target as HTMLAnchorElement;
    const href = target.getAttribute("href")!;
    handleNavigate(e, href, owner);
  };

  const logoLink = document.getElementById("logo");
  const elements = document.getElementsByClassName("footer-link");

  onMount(() => {
    logoLink?.addEventListener("click", onClick);

    for (const element of elements) {
      if (element instanceof HTMLAnchorElement) {
        element.addEventListener("click", onClick);
      }
    }
  });

  onCleanup(() => {
    logoLink?.removeEventListener("click", onClick);

    for (const element of elements) {
      if (element instanceof HTMLAnchorElement) {
        element.removeEventListener("click", onClick);
      }
    }
  });

  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary
        fallback={(error, reset) => {
          return <ErrorComponent error={error} reset={reset} />;
        }}
      >
        {props.children}
        <Portal2 mount={headerLogo} createElement={createMenuButtonDiv}>
          <MenuButton isModal={isModal} />
        </Portal2>
        <Portal2 mount={navMount} createElement={createNavContainerDiv}>
          <Nav />
        </Portal2>
      </ErrorBoundary>
    </Suspense>
  );
};

const Main = () => {
  return <Router root={MainWrapper}>{routes}</Router>;
};

export default Main;
