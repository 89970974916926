import { type RouteLoadFunc, cache, createAsync } from "@solidjs/router";
import type { Accessor } from "solid-js";

import { getPublishedFormBody } from "@base/api/formBodies";
import { getForm } from "@base/api/forms";
import type { Entry } from "@base/models";
import type { FormEntry } from "@base/models/forms/common";
import type {
  FormBodyEntry,
  StaticFormBody,
  StaticFormBodyId,
} from "@base/models/forms/static-form";
import { pullFirstPageData } from "@base/root-data";
import { changeTitle } from "@base/utils/helpers";

export const getFormCached = cache(async (formId: Id) => {
  const form = pullFirstPageData<FormEntry>("form");
    const formBody =
      pullFirstPageData<Entry<StaticFormBody, StaticFormBodyId>>("formBody");

    if (form && formBody) {
      return { form, body: formBody };
    } else {
      const formPromise = getForm(formId);
      const formBodyPromise = getPublishedFormBody(formId);
      const [formRes, formBodyRes] = await Promise.all([
        formPromise,
        formBodyPromise,
      ]);

      const form = formRes.unwrap();
      changeTitle(form.name);
      return { form, body: formBodyRes.unwrap() };
    }
}, "getForm");

export const loadFormData: RouteLoadFunc<
  Accessor<{ form: FormEntry; body: FormBodyEntry } | undefined>
> = ({ params, intent }) => {
  if (intent === "preload") {
    console.warn("preload api call");
  }
  return createAsync(() => getFormCached(params.id))
};
