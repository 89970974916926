import {
  Match,
  Show,
  Switch,
  createSignal,
  onCleanup,
  onMount,
  useContext,
} from "solid-js";

import { loadDarkMode, saveDarkMode, saveRedirectUrl } from "@base/storage";
import { appStoreMethods, userStoreContext } from "@base/store";

import { changeIsOpenModal } from "@utils/modal-menu";

import { Link } from "@comp/custom/Link";

import { LogoutIcon, MiDarkMode, MiLightMode, MiNightSightAuto } from "@icons";

const enum State {
  Light,
  Dark,
  NotPreferred,
}

const LIGHT_THEME = "light-theme";
const DARK_THEME = "dark-theme";

const bodyClassList = document.body.classList;

const getDefaultMode = () => {
  const mode = loadDarkMode();
  if (mode === "light") {
    bodyClassList.add(LIGHT_THEME);
    return State.Light;
  }
  if (mode === "dark") {
    bodyClassList.add(DARK_THEME);
    return State.Dark;
  }
  return State.NotPreferred;
};

export const Nav = () => {
  const store = useContext(userStoreContext);
  const [state, setState] = createSignal(getDefaultMode());

  const media = window.matchMedia("(prefers-color-scheme: dark)");
  let isDark = media.matches;

  const listener = (e: MediaQueryListEvent) => {
    isDark = e.matches;
  };

  onMount(() => {
    media.addEventListener("change", listener, { passive: true });
  });
  onCleanup(() => {
    media.removeEventListener("change", listener);
  });

  const changeColor = () => {
    setState((old) => {
      // if isDark:
      // auto -> light -> dark -> auto ...
      // else
      // auto -> dark -> light -> auto ...

      if (old === State.Light) {
        if (isDark) {
          bodyClassList.remove(LIGHT_THEME);
          bodyClassList.add(DARK_THEME);
          saveDarkMode("dark");
          return State.Dark;
        } else {
          bodyClassList.remove(LIGHT_THEME);
          saveDarkMode(null);
          return State.NotPreferred;
        }
      }

      if (old === State.Dark) {
        if (isDark) {
          bodyClassList.remove(DARK_THEME);
          saveDarkMode(null);
          return State.NotPreferred;
        } else {
          bodyClassList.remove(DARK_THEME);
          bodyClassList.add(LIGHT_THEME);
          saveDarkMode("light");
          return State.Light;
        }
      }

      if (isDark) {
        bodyClassList.add(LIGHT_THEME);
        saveDarkMode("light");
        return State.Light;
      } else {
        bodyClassList.add(DARK_THEME);
        saveDarkMode("dark");
        return State.Dark;
      }
    });
  };

  const onAuth = () => {
    saveRedirectUrl(window.location.toString());
  };

  const logout = () => {
    changeIsOpenModal(false);
    appStoreMethods.logout();
  };

  return (
    <>
      <nav>
        <Show
          when={store.user}
          fallback={
            <>
              <Link href="/login" onClick={onAuth} static>
                {$.auth.login}
              </Link>
              <Link href="/signup" onClick={onAuth} static>
                {$.auth.signup}
              </Link>
            </>
          }
        >
          <Link href="/">{$.home.title}</Link>
          <Link href="/settings">{$.settings.title}</Link>
          <Link href="/f">{$.global.forms}</Link>
        </Show>
        <Link href="/device-info">{$.global.device_info}</Link>
      </nav>
      <button
        type="button"
        class="outline nav-buttons"
        aria-label={$.global.change_color}
        title={$.global.change_color}
        onClick={changeColor}
      >
        <Switch>
          <Match when={state() === State.NotPreferred}>
            <MiNightSightAuto /> Auto
          </Match>
          <Match when={state() === State.Light}>
            <MiLightMode /> Light
          </Match>
          <Match when={state() === State.Dark}>
            <MiDarkMode /> Dark
          </Match>
        </Switch>
      </button>
      <Show when={store.user}>
        <button
          type="button"
          class="outline nav-buttons"
          aria-label={$.auth.logout_description}
          title={$.auth.logout_description}
          onClick={logout}
        >
          <LogoutIcon /> {$.auth.logout}
        </button>
      </Show>
    </>
  );
};
