import type { AppId } from "@base/models";
import { now } from "./helpers";

export const appIdToString = (appId: AppId) => appId.map((n) => n.toString(16)).join("-");

const rand = () => {
    const arr = new Uint32Array(2);
    crypto.getRandomValues(arr);
    const view = new DataView(arr.buffer);
    let uint64 = view.getBigUint64(0, true);
    uint64 = BigInt.asUintN(64, uint64);
    return uint64.toString();
}

export const uid = (): AppId => {
    const n = now();
    const r = rand();

    return [n, r] as AppId;
};
