import { createMemo } from "solid-js";

import { ApiError, EntityError } from "@base/api/main";

import Content from "@comp/blocks/Content";

interface Props {
  error: unknown;
  reset: () => void;
}

export const ErrorComponent = (props: Props) => {
  console.log(props.error);

  const err = createMemo(() => {
    const err = props.error;

    if (err instanceof ApiError) {
      let titleMsg = $.errors.error;

      switch (err.statusCode) {
        case 400:
          titleMsg = $.errors.bad_request;
          break;
        case 401:
          titleMsg = $.errors.unauthorized;
          break;
        case 403:
          titleMsg = $.errors.forbidden;
          break;
        case 404:
          titleMsg = $.errors.not_found;
          break;
        case 500:
          titleMsg = $.errors.internal_server;
          break;
      }

      const msg = err.messages.join("\n");

      return [`${err.statusCode} ${titleMsg}`, msg];
    } else if (err instanceof EntityError) {
      const msg = err.errors.map((e) => e.userMessage).join("\n");

      return [$.errors.error, msg];
    } else {
      return [$.errors.error, $.errors.unknown];
    }
  });

  return <Content title={err()[0]}>{err()[1]}</Content>;
};

export default ErrorComponent;
