import { useNavigate } from "@solidjs/router";
import type { Owner } from "solid-js";
import { runWithOwner } from "solid-js";

import type { ChangeLocation } from "@base/metrics";
import { metricsService } from "@base/metrics";

import { changeIsOpenModal } from "@utils/modal-menu";

// const authRoutes = ["/reset-password", "/signup", "/login"];

export const handleNavigate = (
  e: MouseEvent,
  href: string,
  owner: Owner,
  staticLink = false,
) => {
  changeIsOpenModal(false);

  const url = new URL(href, window.location.origin);
  const { pathname, search, hash, host } = url;

  const data: ChangeLocation = { pathname, search, hash, host };

  metricsService.addChangeLocations(data);

  const isKey = e.ctrlKey || e.metaKey || e.altKey || e.shiftKey;

  if (!staticLink && !isKey) {
    e.preventDefault();

    runWithOwner(owner, () => {
      const navigate = useNavigate();
      navigate(href);
    });
  }
};
