import { AlertType, addMessage } from "@base/store";

import { metricsService } from "../metrics";
import { saveRedirectUrl } from "../storage";
import type { ParamsType } from "./main";
import { ApiError, EntityError, Method, URI, request } from "./main";

export { URI, request, Method };
export type { ParamsType };

const API_ERR_MSG = "Something went wrong. Please try again later.";

export const CLIENT_ERRORS = [400, 401, 403, 404];

export const errorHandle = (error: unknown) => {
  if (error instanceof EntityError) {
    for (const e of error.errors) {
      if (e.message) {
        console.error("api error:", e.message);
      }
      if (e.userMessage) {
        const { text, field } = e.userMessage;

        const msg = field ? `${field}: ${text}` : text;

        addMessage(msg, AlertType.Error);
      } else {
        addMessage(API_ERR_MSG, AlertType.Error);
      }
    }

    return;
  }

  if (error instanceof ApiError) {
    const isClientError = CLIENT_ERRORS.includes(error.statusCode);

    if (!isClientError) {
      metricsService.addError({
        message: error.message,
        name: error.name,
        statusCode: error.statusCode,
      });
    }

    for (const msg of error.messages) {
      if (msg) {
        const err = `api: ${msg}`;
        addMessage(err, AlertType.Error);
      }
    }

    return;
  }

  if (error instanceof Error) {
    metricsService.addError(error);
  } else {
    metricsService.addError({
      message: "",
      name: "unknown error",
      error,
    });
  }

  addMessage(API_ERR_MSG, AlertType.Error);
};

const requiredSignin = () => {
  saveRedirectUrl(window.location.toString());
  console.info("requiredSignin");
};

const metricSend = (uri: string, ms: number) => {
  metricsService.add({
    event: "api-time",
    uri,
    ms,
  });
};

const errorSend = (statusCode: number, name: string, message: string) => {
  metricsService.addError({
    message,
    name,
    statusCode,
  });
};

export const get = <D>(path: string, params?: ParamsType) =>
  request<D>({
    path: URI + path,
    params,
    method: Method.Get,
    requiredSignin,
    metricSend,
    errorSend,
  });

export const post = <D>(
  path: string,
  body?: any,
  options?: {
    params?: ParamsType;
    timeout?: number;
  },
) =>
  request<D>({
    path: URI + path,
    body,
    method: Method.Post,
    requiredSignin,
    metricSend,
    errorSend,
    ...options,
  });

export const put = <D>(path: string, body: any, params?: ParamsType) =>
  request<D>({
    path: URI + path,
    params,
    body,
    method: Method.Put,
    requiredSignin,
    metricSend,
    errorSend,
  });

export const patch = <D>(path: string, body: any, params?: ParamsType) =>
  request<D>({
    path: URI + path,
    params,
    body,
    method: Method.Patch,
    requiredSignin,
    metricSend,
    errorSend,
  });

export const del = <D>(path: string, body?: any, params?: ParamsType) =>
  request<D>({
    path: URI + path,
    params,
    body,
    method: Method.Delete,
    requiredSignin,
    metricSend,
    errorSend,
  });
