import type { Id } from "../models";
import type {
  FormBodyEntry,
  StaticFormData,
} from "../models/forms/static-form";
import { get, post, put } from "./index";

// BODIES

const bodiesPrefix = (id: Id) => `forms/${id}/bodies/`;

interface GetFormBodiesRes {
  bodies: FormBodyEntry[];
}

// GET forms/{formId}/bodies
export const getFormBodies = (formId: Id) =>
  get<GetFormBodiesRes>(bodiesPrefix(formId));

export interface ActualFormBodies {
  draft: FormBodyEntry | null;
  published: FormBodyEntry | null;
}

// GET forms/{formId}/bodies/actual
export const getActualFormBody = (formId: Id) =>
  get<ActualFormBodies>(bodiesPrefix(formId) + "actual");

// GET forms/{formId}/bodies/published
export const getPublishedFormBody = (formId: Id) =>
  get<FormBodyEntry>(bodiesPrefix(formId) + "published");

// GET forms/{formId}/bodies/history
export const getFormBodiesHistory = (formId: Id) =>
  get<{ history: [] }>(bodiesPrefix(formId) + "history");

// GET forms/{formId}/bodies/{version}
export const getFormBody = (formId: Id, version: Id) =>
  get<{ body: FormBodyEntry }>(bodiesPrefix(formId) + version);

// PUT forms/{formId}/bodies/
export const editFormBody = (
  formId: Id,
  data: StaticFormData,
  publish?: boolean,
) =>
  put<{ body: FormBodyEntry }>(bodiesPrefix(formId), {
    data,
    publish,
  });

// POST forms/{formId}/bodies/publish
export const publishFormBody = (formId: Id) =>
  post(bodiesPrefix(formId) + "publish");
