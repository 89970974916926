import type { Entry, Id } from "@models";
import type { Form, FormEntry } from "@models/forms/common";
import type {
  FormViewType,
  StaticFormBody,
  StaticFormBodyId,
  StaticFormData,
  StaticFormType,
} from "@models/forms/static-form";

import { del, get, patch, post } from "./index";

const prefix = "forms/";

interface GetFormsRes {
  forms: FormEntry[];
  isSelf?: true;
}

// GET forms/
export const getForms = () => get<GetFormsRes>(prefix);

interface GetUserFormsRes {
  forms: FormEntry[];
}

// GET forms/?user=abc123
export const getUserForms = (id: Id) =>
  get<GetUserFormsRes>(prefix, { user: id });

// GET forms/?username=my_name
// export const getUserFormsByUsername = (
//   username: string
// ): BodyPromise<{ forms: Form[] }> => get(usersPrefix + username + "/forms/");

// GET forms/{id}
export const getForm = (id: Id) => get<FormEntry>(prefix + id);

export interface FormCreateBody {
  name: string;
  description: string;
  staticFormType: StaticFormType;
  viewType: FormViewType;
  data: StaticFormData;
  // settings: object;
  publishBody?: boolean;
}

interface CreateFormBodyRes {
  form: Form;
  body: Entry<StaticFormBody, StaticFormBodyId>;
}

// POST forms/
export const createForm = (form: FormCreateBody) =>
  post<CreateFormBodyRes>(prefix, form);

interface EditFormBodyRes {
  form: { id: number };
}

// PATCH forms/{id}
export const editForm = (id: Id, form: FormCreateBody) =>
  patch<EditFormBodyRes>(prefix + id, form);

// POST forms/{id}/like
export const likeForm = (formId: Id, like: boolean) =>
  post<{ count: number; liked: boolean }>(`${prefix}${formId}/like`, { like });

// POST forms/{id}/publish
export const publish = (formId: Id) => post(`${prefix}${formId}/publish`);

// DELETE forms/{id}
export const removeForm = (formId: Id) => del(prefix + formId);

// POST forms/{id}/disable
export const disableForm = (formId: Id, disable: boolean) =>
  post(`${prefix}${formId}/disable`, { disable });
