import { URI } from "@api";

import { isDev } from "./env";

interface WsMessage<T extends string = string, D = unknown> {
  type: T;
  data?: D;
}

const u = (url: string) => `${URI}websockets/${url}`;

export const createWs = <T extends string = string, D = unknown>(
  url: string,
  cb?: (data: WsMessage) => void,
): Promise<WebSocket> => {
  return new Promise((res, rej) => {
    try {
      const wsUrl = new URL(u(url), window.location.origin);

      if (wsUrl.protocol === "http:") {
        wsUrl.protocol = wsUrl.protocol.replace("http", "ws");
      } else if (wsUrl.protocol === "https:") {
        wsUrl.protocol = wsUrl.protocol.replace("https", "wss");
      } else if (!["ws:", "wss:"].includes(wsUrl.protocol)) {
        return rej(Error(`unknown protocol: ${wsUrl.protocol}`));
      }
      const socket = new WebSocket(wsUrl.href);
      socket.onopen = () => {
        if (isDev) {
          socket.onclose = () => {
            console.log("socket onclose");
          };
        }
        socket.onmessage = (event) => {
          if (cb && event.data && typeof event.data === "string") {
            try {
              const data: unknown = JSON.parse(event.data);
              if (typeof data === "object") {
                cb(data as WsMessage<T, D>);
              } else {
                console.error("typeof data is not object");
              }
              // log('socket onmessage', event)
            } catch (e) {
              console.error(e);
            }
          } else {
            event.data;
          }
        };
        res(socket);
      };
      socket.onerror = (error) => {
        rej(error);
      };
    } catch (e) {
      rej(e);
    }
  });
};

export const sendMessage = <T extends string, D>(
  socket: WebSocket,
  data: WsMessage<T, D>,
) => {
  if (typeof data === "object") {
    socket.send(JSON.stringify(data));
  } else {
    socket.send(data);
  }
};

export const createBinWs = (url: string): Promise<WebSocket> => {
  return new Promise((res, rej) => {
    try {
      const wsUrl = new URL(u(url), window.location.origin);

      if (wsUrl.protocol === "http:") {
        wsUrl.protocol = wsUrl.protocol.replace("http", "ws");
      } else if (wsUrl.protocol === "https:") {
        wsUrl.protocol = wsUrl.protocol.replace("https", "wss");
      } else if (!["ws:", "wss:"].includes(wsUrl.protocol)) {
        return rej(Error(`unknown protocol: ${wsUrl.protocol}`));
      }
      const socket = new WebSocket(wsUrl.href);
      socket.binaryType = "arraybuffer";
      socket.onopen = () => {
        if (isDev) {
          socket.onclose = () => {
            console.log("socket onclose");
          };
        }
        res(socket);
      };
      socket.onerror = (error) => {
        rej(error);
      };
    } catch (e) {
      rej(e);
    }
  });
};

export const parseWsData = <T extends object>(
  data: unknown,
): T | DataView | null => {
  if (data && typeof data === "string") {
    try {
      const parsed = JSON.parse(data);
      if (parsed) {
        return parsed as T;
      } else {
        console.error("parsed is null");
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  } else if (data && data instanceof ArrayBuffer) {
    const view = new DataView(data);
    return view;
  } else {
    console.error("websocket data type error", typeof data);
    return null;
  }
};
