import miAdd from "@material-symbols/svg-400/rounded/add.svg?raw";
import miAddCircle from "@material-symbols/svg-400/rounded/add_circle.svg?raw";
import miArrowBack from "@material-symbols/svg-400/rounded/arrow_back_ios_new.svg?raw";
import miClose from "@material-symbols/svg-400/rounded/close.svg?raw";
import miDarkMode from "@material-symbols/svg-400/rounded/dark_mode.svg?raw";
import miDelete from "@material-symbols/svg-400/rounded/delete.svg?raw";
import miEdit from "@material-symbols/svg-400/rounded/edit.svg?raw";
import miExpandLess from "@material-symbols/svg-400/rounded/expand_less.svg?raw";
import miExpandMore from "@material-symbols/svg-400/rounded/expand_more.svg?raw";
import miFavoriteFill from "@material-symbols/svg-400/rounded/favorite-fill.svg?raw";
import miFavorite from "@material-symbols/svg-400/rounded/favorite.svg?raw";
import miImage from "@material-symbols/svg-400/rounded/image.svg?raw";
import miInput from "@material-symbols/svg-400/rounded/input.svg?raw";
import miLightMode from "@material-symbols/svg-400/rounded/light_mode.svg?raw";
import miLogout from "@material-symbols/svg-400/rounded/logout.svg?raw";
import miMenu from "@material-symbols/svg-400/rounded/menu.svg?raw";
import miNightSightAuto from "@material-symbols/svg-400/rounded/night_sight_auto.svg?raw";
import miProgress from "@material-symbols/svg-400/rounded/progress_activity.svg?raw";
import miSettings from "@material-symbols/svg-400/rounded/settings.svg?raw";
import miTextFields from "@material-symbols/svg-400/rounded/text_fields.svg?raw";
import miVisibility from "@material-symbols/svg-400/rounded/visibility.svg?raw";
import miVisibilityOff from "@material-symbols/svg-400/rounded/visibility_off.svg?raw";
import { template } from "solid-js/web";

const icon = (svg: string, aria?: string) => {
  // width, height, viewBox="0 96 960 960", fill="currentColor"?
  const element = /* @__PURE__ */ template(svg, false)();

  if (aria) {
    element.setAttribute("aria-label", aria);
  } else {
    element.setAttribute("aria-hidden", "true");
  }

  return element;
};

const menu = icon(miMenu, "menu");
export const MiMenu = () => menu.cloneNode(true);

const lightMode = icon(miLightMode, "light mode");
export const MiLightMode = () => lightMode.cloneNode(true);
const darkMode = icon(miDarkMode, "dark mode");
export const MiDarkMode = () => darkMode.cloneNode(true);
const nightSightAuto = icon(miNightSightAuto, "night sight auto");
export const MiNightSightAuto = () => nightSightAuto.cloneNode(true);

const addCircle = icon(miAddCircle, "add item");
export const MiAddCircle = () => addCircle.cloneNode(true);

const visibility = icon(miVisibility, "show password");
export const MiVisibility = () => visibility.cloneNode(true);
const visibilityOff = icon(miVisibilityOff, "hide password");
export const MiVisibilityOff = () => visibilityOff.cloneNode(true);

const arrowBack = icon(miArrowBack, "back");
export const MiArrowBack = () => arrowBack.cloneNode(true);

const add = icon(miAdd, "add");
export const MiAdd = () => add.cloneNode(true);

const del = icon(miDelete, "delete");
export const MiDelete = () => del.cloneNode(true);

const expandMore = icon(miExpandMore, "down");
export const MiExpandMore = () => expandMore.cloneNode(true);

const expandLess = icon(miExpandLess, "up");
export const MiExpandLess = () => expandLess.cloneNode(true);

const favorite = icon(miFavorite, "like");
export const MiFavorite = () => favorite.cloneNode(true);

const favoriteFill = icon(miFavoriteFill, "liked");
export const MiFavoriteFill = () => favoriteFill.cloneNode(true);

const input = icon(miInput, "form field");
export const MiInput = () => input.cloneNode(true);

const image = icon(miImage, "image");
export const MiImage = () => image.cloneNode(true);

const textFields = icon(miTextFields, "markdown");
export const MiTextFields = () => textFields.cloneNode(true);

const edit = icon(miEdit, "edit");
export const EditIcon = () => edit.cloneNode(true);

const progress = icon(miProgress, "progress");
export const ProgressIcon = () => progress.cloneNode(true);

const close = icon(miClose, "close");
export const CloseIcon = () => close.cloneNode(true);

const logout = icon(miLogout, "logout");
export const LogoutIcon = () => logout.cloneNode(true);

const settings = icon(miSettings, "settings");
export const SettingsIcon = () => settings.cloneNode(true);
