import type { Component, ParentProps } from "solid-js";
import { getOwner } from "solid-js";

import { handleNavigate } from "@utils/navigate";

interface LinkProps {
  href?: string;
  onClick?: () => void;
  // if true, then the link will not be intercepted
  static?: boolean;
  anchor?: string;
  qs?: Record<string, string>;
  class?: string;
  id?: string;
  title?: string;
  // [otherProps: string]: any;
}

export const Link: Component<ParentProps<LinkProps>> = (props) => {
  const { href, onClick, qs, ...otherProps } = props;
  const owner = getOwner()!;

  let fullHref = href ?? window.location.pathname;

  if (qs) {
    const u = new URLSearchParams(qs);
    fullHref = `${fullHref}?${u.toString()}`;
  }

  if (props.anchor) {
    fullHref = `${fullHref}#${props.anchor}`;
  }

  const handleClick = (e: MouseEvent) => {
    onClick?.();
    handleNavigate(e, fullHref, owner, props.static);
  };

  return (
    <a
      title={props.title}
      {...otherProps}
      href={fullHref}
      onClick={handleClick}
      class={props.class}
      id={props.id}
    >
      {props.children}
    </a>
  );
};
