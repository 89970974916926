import { uid } from "@utils/app-id";
import { parseAppVersion } from "@utils/helpers";

import type { Entry, User } from "@models";
import type { DraftFormData } from "@models/forms/common";

import { APP_VERSION } from "./env";
import type { AppId } from "./models";

export const loadRedirectUrl = () => localStorage.getItem("redirect_url");
export const saveRedirectUrl = (url: string | null) => {
  if (url) {
    localStorage.setItem("redirect_url", url);
  } else {
    localStorage.removeItem("redirect_url");
  }
};

export const loadUser = () => {
  const val = localStorage.getItem("user");
  if (val) {
    return JSON.parse(val) as Entry<User>;
  } else {
    return null;
  }
};
export const saveUser = (user: Entry<User> | null) => {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
  } else {
    localStorage.removeItem("user");
  }
};

const loadAppId = (): AppId => {
  const appId = localStorage.getItem("app_id");
  const init = () => {
    const appId = uid();
    localStorage.setItem("app_version", APP_VERSION);
    localStorage.setItem("app_id", JSON.stringify(appId));
    return appId;
  };
  if (!appId) {
    return init();
  }
  const oldAppVersion = localStorage.getItem("app_version") ?? "0.0.0";
  const oldPath = parseAppVersion(oldAppVersion)[2];
  if (oldPath < 4) {
    return init();
  }
  return JSON.parse(appId) as AppId;
};

export const appId = loadAppId();

type Mode = "light" | "dark" | null;
export const loadDarkMode = () => localStorage.getItem("dark_mode") as Mode;
export const saveDarkMode = (value: Mode) => {
  if (value === null) {
    localStorage.removeItem("dark_mode");
  } else {
    localStorage.setItem("dark_mode", value);
  }
};

export const loadForm = () => {
  const val = localStorage.getItem("new_form");
  if (val) {
    return JSON.parse(val) as DraftFormData;
  } else {
    return null;
  }
};
export const saveForm = (value: DraftFormData | null) => {
  if (!value) {
    localStorage.removeItem("new_form");
  } else {
    localStorage.setItem("new_form", JSON.stringify(value));
  }
};
